<template >
    <div>
      <div class="my-6 flex items-center justify-between">
    <div>
      <h3 class="font-bold">
        Editar Promoter > <span class="text-primary">{{  promotoEvento.documento }}</span>
      </h3>

      <span class="text-sm text-gray-500"
        >Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
      </span>
    </div>
    
  </div>

   <div class="flex mb-6 justify-end">
    <div class="ml-3">
      <Button @click="edit" color="primary" text="Salvar" />
    </div>

  </div> 
  <div class="card rounded-lg bg-white shadow-sm border p-8">
    <div class="mb-6">
      <span class="text-sm text-gray-500">Informações básicas: </span>
    </div>

    <div class="flex">
      <div class="flex-1 mr-3">
        <Input
         v-model="promotoEvento.documento"
          type="cpfcnpj"
          label="Promoter CPF/CNPJ"
          placeholder="Promoter"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
         v-model="promotoEvento.usuario"
          type="default"
          label="Email"
          placeholder="Email"
        />
      </div>

      <div class="flex-1 mr-3 relative">
        <Input
          v-model="promotoEvento.senha"
          :type="type"
          placeholder="Senha"
          label="Senha"
        />
        <i :class="`gg-${icon} icon`" @click="changeInputType"></i>
      </div>
    </div>
  </div>
  <div class="card rounded-lg bg-white shadow-sm border p-8 mt-3">
    <div class="mb-6">
      <span class="text-sm text-gray-500">Eventos Vinculados: </span>
     <div class="flex mb-6 justify-end">
          <div class="mr-3">
            <Button color="primary" text="Criar novo vínculo" @click="createVinculo" />
          </div>
      </div>
      </div>
      <div class="flex">
         <div class="flex-1 mr-3">
          <Input
          v-model="vinculo.nome"
          type="default"
          label="Nome"
          placeholder="Nome"
          />
         </div>

         <div class="flex-1 mr-3 mb-4">
           <Input
            v-model="vinculo.comissao"
            type="tax"
            label="Comissão"
            placeholder="0"
          />
         </div>
      </div>
      <div class="flex">
        <div class="flex-1 mr-3">
          <Input
            v-model="vinculo.evento"
            id="evento"
            type="datalist"
            label="Eventos"
            placeholder="Eventos"
            :data="eventos"
          />
        </div>
      <div class="flex-1 mr-3">
          <Input
            v-model="vinculo.permissao_cancelar"
            id="permissao"
            type="select"
            :data="permissoes"
            placeholder="Permissão"
            label="Permissão"
          />
      </div>
    </div>

    <div class="mt-3" >
      <DataTable  
      :headers="headers"
      :data="eventData"
      :options="options"
      :exports="true"
      color="primary"
    />
    </div>
  </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET,PUT, POST, DELETE } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import DataTable from "../../components/Elements/Datatable.vue";
import {toDate} from "../../services/formater";



export default {
  name: "EditarUsuario",
  components: {
    Input,
    Button,
    DataTable
  },
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {

    const headers = [
      { key: "Id", value: "evento_id" },
      { key: "Evento", value: "evento_nome" },
      { key:"Permite cancelamento", value:"permissao"},
      {key: "Comissão", value: "comissao"},
      { key:"Criado em", value:"data"},
      { key:"Ações", value:""}
    ];

    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const icon = ref("eye");
    
    const promotoEvento = ref({
      documento: "", 
      usuario: "",
      senha : ""
    });
    
    const user = ref({
      id: "",
      usuario: "",
    });

    const type = ref("password");

    const data = ref({
      "id_evento": "",
      "id_promoter_usuario": "",
      "permissao_cancelar": "",
      "nome_promoter":"",
      "comissao" : 0
    });

    const eventos = ref([]);
    const eventosRes =  ref([]);

    const vinculo = ref({
      "evento": "",
      "id_promoter_usuario" : "",
      "permissao_cancelar":1,
      "nome":"",
      "comissao" : ""
    });

    const eventData = ref([])
    const eventDataTemp = ref([])

    const permissoes = ref([]);

    function map(){
        eventData.value = eventDataTemp.value.map( element => {
        element.datacriacao = new Date(element.data).toLocaleDateString("pt-br");
        if(element.cancelamento === 1){
          element.permissao = "sim";
        }
        else
        {
          element.permissao = "não";
        }
         return element;
      });
    }

    onMounted(async () => {
      loader.open();
      //data array of promoters
      const data = await GET(`promoters/${route.params.id}/promoters_id`);


      //get the first value of array:
      promotoEvento.value.documento = data[0].documento
      promotoEvento.value["usuario_id"] = data[0].usuario_id
      promotoEvento.value.usuario = data[0].usuario

      user.value.id = data[0].usuario_id
      

      // get user vinculated in promoter
     
      const data2 = await GET(`usuarios/${promotoEvento.value.usuario_id}`);
     
      user.value.usuario = data2.usuario

      //get events vinculated by promoter


      eventDataTemp.value =  await GET(`usuarios_vinculos/${route.params.id}/eventos_v2`);

      eventDataTemp.value.map((item)=>{
        if(item.comissao == "" || item.comissao == null || item.comissao == undefined){
          item.comissao = "0.00";
        }

        item.comissao = item.comissao + "%";

        item.data = new Date(item.data).toLocaleDateString("pt-br");

      })

      map();

      //get all events

      eventosRes.value = await GET(`event`);
      eventosRes.value.forEach( evento => eventos.value.push({ label: evento.nome + " - " + toDate(evento.data), value: evento.id }))

      //create permissions
      const perm = [{id:1, label:"Sim", value : 1},{id:2,label:"Não", value : 0}]

      perm.forEach(p => permissoes.value.push( { label: p.label, value: p.value}) )

      vinculo.value["permissao"] = permissoes.value[0].label

      loader.close();
    });

    const edit = async () => {
      const validate = isEmpty(promotoEvento.value.usuario && promotoEvento.value.documento);

      const body = { 
        documento : promotoEvento.value.documento,
        usuario :   promotoEvento.value.usuario,
        senha : promotoEvento.value.senha
      }



      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open(
          "Atenção",
          "Deseja realmente editar esse Promoter?",
          "warning",
          "Sim",
          async () => {
            loader.open();
            try {

              const response = await PUT(`promoters/${route.params.id}`,body);
              alert.open(
                "Sucesso!",
                `Usuário ${promotoEvento.value.usuario} foi editado com sucesso!`,
                "success"
              );
              router.push("/promoter");
            } catch (e) {
              alert.open("Atenção!", e, "warning");
            }
            loader.close();
          },
          modal.close()
        );
      }
    };



    const deleteItem = (param) =>{


       modal.open(
          "Atenção",
          "Deseja realmente deletar esse evento?",
          "warning",
          "Sim",
        async () => {
          loader.open();
          // function to delete the event
          await DELETE(`usuarios_vinculos/${param.id}`); 
          eventData.value = eventData.value.filter((item) => item.id != param.id);
          
          loader.close(); 
        },
        modal.close());
     

    }

    const createVinculo = async () =>{
      const obj = ref({});

      obj.value = { ... vinculo.value,
                  "nome_promoter" : vinculo.value.nome,
                  "id_evento" : vinculo.value.evento,
                }
      delete obj.value.evento
      delete obj.value.nome


      delete vinculo.value["id_promoter_usuario"];


      const [ eventoSelecionado ] = eventos.value.filter(evento => evento.label == vinculo.value.evento);


      eventoSelecionado ?  obj.value["id_evento"] = eventoSelecionado.value :  obj.value["id_evento"] = "" ;


      const [ permSelecionado ] = permissoes.value.filter(permicao => permicao.value == vinculo.value["permissao_cancelar"]);
      
      obj.value["id_promoter_usuario"] = promotoEvento.value.usuario_id;

      const validate = isEmpty(vinculo.value);
      if (validate) {

        alert.open("Atenção!", validate, "warning");
        vinculo.value["id_evento"] = null
      } else {
        loader.open();

      try {
          const response = await POST("usuarios_vinculos/create_promoters_vinculo", obj.value);
          const obj2 = {
            id: response.generatedMaps[0].id,
            datacriacao: new Date(response.generatedMaps[0].created_at).toLocaleDateString("pt-br"),
            evento : eventoSelecionado.label,
            permissao: ( vinculo.value["permissao_cancelar"]? 'sim' : 'não')
          }

          vinculo.value.nome = "";
          vinculo.value.evento = "";
          vinculo.value.comissao = "";
          vinculo.value["permissao_cancelar"] = 1;

          eventData.value.push(obj2);

          alert.open(
            "Sucesso!",
            `Vínculo criado com sucesso!`,
            "success"
          );
         
        } catch (e) {
          alert.open("Atenção!", e, "danger");
        }
        loader.close();
      }
    }
     const options = [
      //{ icon: "pen", path: '/promoter/editar' , title: "Editar promoter" },
      { icon: "trash", action: (param) => deleteItem(param), title: "Excluir Vinculo" },
    ];

    
    const changeInputType = () => {
      type.value = type.value == 'password' ? 'default' : 'password';
      icon.value = icon.value == 'eye' ? 'eye-alt' : 'eye';
    };



    return { 
      edit, 
      user,
      promotoEvento,
      eventData,
      headers,
      options,
      vinculo,
      eventos,
      permissoes,
      createVinculo,
      icon,
      changeInputType,
      type,
    };
  },
};
</script>

<style scoped>

  .icon{
    cursor: pointer;
    position: absolute;
    right:20px;
    top: 39%;
  }

  .btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>